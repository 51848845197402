/**=====================
    Card CSS Start
==========================**/
.checkbox_animated {
    cursor: pointer;
    position: relative;
    margin: 0 16px 0 0;
    z-index: 0;

    &:before {
        transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
        transform: rotate(-45deg) scale(0, 0);
        @include pos;
        left: 0.25rem;
        top: 0.225rem;
        z-index: 1;
        @include pseudowh($width: 0.75rem, $height: 0.375rem);
        border: 2px solid var(--theme-color);
        border-top-style: none;
        border-right-style: none;
    }

    &:after {
        @include pos;
        top: -0.125rem;
        left: 0;
        @include pseudowh($width: 1.3rem, $height: 1.3rem);
        background: $white;
        border: 2px solid $gray-light;
        cursor: pointer;
    }

    &:checked:before {
        transform: rotate(-45deg) scale(1, 1);
    }
}

.title-header {
    padding-bottom: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    color: $dark-card-background;
    font-size: 22px;

    a {
        font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
        color: $theme-font-color;
        text-transform: capitalize;
        display: block;

        &.btn {
            padding: calc(7px + (10 - 7) * ((100vw - 320px) / (1920 - 320))) calc(14px + (32 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-size: 14px;
            color: $white;
        }
    }

    &-1 {
        @include flex_common($dis: flex, $align: center, $justify: space-between);
    }

    &-block {
        display: block;

        .card-order-section {
            margin-top: 20px;
            border-top: 1px solid rgba($color: $black1, $alpha: 0.15);
            border-bottom: 1px solid rgba($color: $black1, $alpha: 0.15);
            padding: 10px 0;

            ul {
                margin: -8px;
                color: $black1;

                li {
                    display: inline-block;
                    margin: 0 15px;
                    font-size: 18px;
                    position: relative;
                    color: $theme-body-font-color;

                    &::before {
                        @include pos($pos: absolute, $content: "/");
                        top: 0;
                        right: -15px;
                        @include pseudowh($width: 0, $height: 100%);
                    }

                    &:first-child {
                        margin-left: 15px;
                    }

                    &:last-child {
                        margin-right: 0;

                        &::before {
                            content: none;
                        }
                    }
                }
            }
        }

        .custome-breadcrum {
            .breadcrumb {
                .breadcrumb-item {
                    a {
                        color: $grey-13;
                    }
                }
            }
        }
    }

    h5 {
        font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 700;
        letter-spacing: 0.03em;

        &:not(.mb-0),
        &:not(.m-0) {
            margin-bottom: 0;
            text-transform: $card-header-font-transform;
        }
    }

    &.option-title {
        @include flex_common($dis: flex, $align: center, $justify: space-between);

        @include mq-max(sm) {
            h5 {
                display: inline-block;
            }

            .selected-options {
                position: fixed;
                bottom: 60px;
                z-index: 1;
                right: 20px;
            }
        }

        .selected-options {
            ul {
                [dir="rtl"] & {
                    padding-right: 15px;
                }

                @include mq-max(sm) {
                    margin-left: 0;
                    box-shadow: 0 0 5px rgba(34, 34, 34, 0.23);
                }
            }
        }

        .right-options {
            @include mq-max(sm) {
                text-align: center;
            }

            ul {
                display: flex;
                align-items: center;
                gap: calc(9px + (15 - 9) * ((100vw - 320px) / (1920 - 320)));

                li {
                    &:last-child {
                        margin-left: auto;
                    }
                }
            }
        }
    }
}

.card {
    margin-bottom: $card-margin-bottom;
    border: none;
    letter-spacing: 0.5px;
    border-radius: 15px;
    padding: 25px;

    &-table {
        padding: calc(14px + (25 - 14) * ((100vw - 320px) / (1920 - 320)));
    }

    &.card-hover {
        transition: all 0.3s ease;

        &:hover {
            box-shadow: 0 0 25px rgba($black1, 0.1);
            transition: all 0.4s ease;
            transform: translateY(-5px);
        }
    }

    &.card-tiles {
        border: 1px solid #ddd;
    }

    .card-header {
        background-color: $card-header-bg-color;
        padding: 0;
        border-bottom: $card-border-color;
        position: relative;
        margin-bottom: 16px;

        &--2 {
            @include flex_common($dis: flex, $align: center, $justify: space-between);
            padding: 40px;
        }

        &-1 {
            margin: 40px 0 20px;
            color: $dark-card-background;

            h5 {
                font-size: 20px;
                font-weight: 700;
            }
        }

        &-2 {
            color: $dark-card-background;
            margin-bottom: 30px;

            h5 {
                font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 700;
            }
        }

        &-block {
            display: block;
            padding: 40px;

            .card-order-section {
                margin-top: 20px;
                border-top: 1px solid rgba($color: $black1, $alpha: 0.15);
                border-bottom: 1px solid rgba($color: $black1, $alpha: 0.15);
                padding: 10px 0;

                ul {
                    margin: -8px;

                    li {
                        display: inline-block;
                        margin: 8px;
                        position: relative;

                        &::before {
                            @include pos($pos: absolute, $content: "/");
                            top: 0;
                            right: -10px;
                            @include pseudowh($width: 0, $height: 100%);
                        }

                        &:last-child {
                            &::before {
                                content: none;
                            }
                        }
                    }
                }
            }

            .custome-breadcrum {
                .breadcrumb {
                    .breadcrumb-item {
                        a {
                            color: $grey-13;
                        }
                    }
                }
            }
        }

        &-top {
            padding: 16px;
        }

        &.card-no-border {
            border-bottom: none !important;
        }

        h5 {
            font-size: 30px;
            font-weight: 700;

            &:not(.mb-0),
            &:not(.m-0) {
                margin-bottom: 0;
                text-transform: $card-header-font-transform;
            }
        }

        >span {
            font-size: $card-header-span-size;
            color: $card-header-span-color;
            margin-top: 5px;
            display: block;
            letter-spacing: 1px;
        }

        .card-header-right {
            border-radius: 0 0 0 7px;
            right: 35px;
            top: 33px;
            display: inline-block;
            padding: 8px 0;
            position: absolute;
            background-color: $white;
            z-index: 1;

            .card-option {
                text-align: right;
                @include pseudowh($width: 35px, $height: 20px);
                overflow: hidden;
                transition: 0.3s ease-in-out;

                li {
                    display: inline-block;

                    &:first-child {
                        i {
                            transition: 1s;
                            font-size: 16px;
                            color: var(--theme-color);

                            &.icofont {
                                color: unset;
                            }
                        }
                    }
                }
            }

            i {
                margin: 0 5px;
                cursor: pointer;
                color: $dark-color;
                line-height: 20px;

                &.icofont-refresh {
                    font-size: 13px;
                }
            }
        }

        .best-selling-box {
            display: flex;
            align-items: center;

            span {
                margin-right: 6px;
                font-weight: 700;
                color: $title-color;

                [dir="rtl"] & {
                    margin-right: unset;
                    margin-left: 6px;
                }
            }

            .dropdown {
                .dropdown-toggle {
                    color: $content-color;
                }
            }
        }
    }

    .card-body {
        padding: 0;
        background-color: $card-body-bg-color;

        &-2 {
            padding: 16px;
        }

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .sub-title {
        border-bottom: 1px solid rgba($black, 0.1);
        padding-bottom: 5px;
        margin-bottom: 8px;
        font-size: 18px;
    }

    .card-footer {
        background-color: $card-footer-bg-color;
        border-top: $card-border-color;
        padding: $card-padding;
        border-bottom-left-radius: $card-border-radious;
        border-bottom-right-radius: $card-border-radious;
    }

    &.card-load {
        .card-loader {
            position: absolute;
            top: 0;
            left: 0;
            @include pseudowh;
            display: flex;
            background-color: rgba($white, 0.7);
            z-index: 8;
            align-items: center;
            justify-content: center;

            i {
                margin: 0 auto;
                color: var(--theme-color);
                font-size: 20px;
            }
        }
    }

    &.full-card {
        position: fixed;
        top: 0;
        z-index: 99999;
        box-shadow: none;
        right: 0;
        border-radius: 0;
        border: 1px solid $light-semi-gray;
        @include pseudowh($width: calc(100vw - 12px), $height: calc(100vh));

        .card-body {
            overflow: auto;
        }
    }
}

.card-absolute {
    margin-top: 20px;

    .card-header {
        position: absolute;
        top: -20px;
        left: 15px;
        border-radius: 0.25rem;
        padding: 10px 15px;

        h5 {
            font-size: 17px;
        }
    }

    .card-body {
        margin-top: 10px;
    }
}

.card-header {
    .border-tab {
        margin-bottom: -13px;
    }
}

.custom-card {
    overflow: hidden;
    padding: 30px;

    .card-header {
        padding: 0;

        img {
            border-radius: 50%;
            margin-top: -100px;
            transform: scale(1.5);
        }
    }

    .card-profile {
        text-align: center;

        img {
            height: 110px;
            padding: 7px;
            background-color: $white;
            z-index: 1;
            position: relative;
        }
    }

    .card-social {
        text-align: center;

        li {
            display: inline-block;
            padding: 15px 0;

            &:last-child {
                a {
                    margin-right: 0;
                }
            }

            a {
                padding: 0;
                margin-right: 15px;
                color: rgb(188, 198, 222);
                font-size: 16px;
                transition: all 0.3s ease;

                &:hover {
                    color: var(--theme-color);
                    transition: all 0.3s ease;
                }
            }
        }
    }

    .profile-details {
        h6 {
            margin-bottom: 30px;
            margin-top: 10px;
            color: $theme-body-sub-title-color;
            font-size: 14px;
        }
    }

    .card-footer {
        padding: 0;

        >div {
            +div {
                border-left: 1px solid $light-semi-gray;
            }

            padding: 15px;
            text-align: center;

            h3 {
                margin-bottom: 0;
                font-size: 24px;
            }

            h6 {
                font-size: 14px;
                color: $theme-body-sub-title-color;
            }

            h5 {
                font-size: 16px;
                margin-bottom: 0;
            }

            i {
                font-size: 24px;
                display: inline-block;
                margin-bottom: 15px;
            }

            .m-b-card {
                margin-bottom: 10px;
            }
        }
    }
}

.card {
    .pagination-box {
        margin: 0 auto;
        padding-bottom: 30px;
        padding-top: 5px;
    }
}

/**=====================
      Card CSS End
  ==========================**/