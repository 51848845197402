/**=====================
     21. Select 2 CSS Start
==========================**/
.select2 {
    max-width: 100%;
}

/* =========================================================================== */
.tracker-title {
    font-size: 28px;
    letter-spacing: 1.2px;
    margin: 0;
    font-weight: 500;
}

.tracker-number {
    p {
        margin-bottom: 10px;
        letter-spacing: 1.2px;

        span {
            font-weight: 500;
            margin-left: 10px;
            color: $black1;
        }
    }
}

.order-left-image {
    margin-top: 0;
    display: flex;
    align-items: center;

    @include mq-max(lg) {
        display: block;
    }

    .tracking-product-image {
        margin-right: 40px;
        width: 24%;

        [dir="rtl"] & {
            margin-right: 0;
            margin-left: 40px;
        }

        @include mq-max(lg) {
            margin-bottom: 18px;
            width: 50%;
            margin-left: auto;
            margin-right: auto;

            [dir="rtl"] & {
                margin-right: auto;
                margin-left: auto;
            }
        }

        @include mq-max(md) {
            margin: 0 auto;
            margin-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
        }
    }

    .order-image-contain {
        width: calc(100% - 30px);

        @include mq-max(lg) {
            width: 100%;
        }

        p {
            margin-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
            letter-spacing: 0.05em;

            span {
                font-weight: 700;
                margin-left: 10px;
                color: #4a5568;

                [dir="rtl"] & {
                    margin-left: 0;
                    margin-right: 10px;
                }
            }
        }

        h4 {
            font-size: 22px;
            letter-spacing: 0.03em;
            margin-bottom: calc(12px + (23 - 12) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 700;

            @include mq-max(md) {
                font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
            }
        }

        h5 {
            font-size: 18px;
            letter-spacing: 0.03em;
            margin-top: calc(12px + (23 - 12) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 600;
        }
    }
}

.order-address {
    margin-top: 50px;

    @include mq-max(2xs) {
        margin-top: 20px;
    }

    li {
        display: flex;
        align-items: flex-start;

        @include mq-max(2xs) {
            display: block;
        }

        .left-box {
            text-transform: capitalize;
            min-width: 210px;
            padding: 10px 0;

            h6 {
                font-weight: 500;

                @include mq-max(2xs) {
                    color: #7e7e7e !important;
                    margin-bottom: -13px;
                    padding-bottom: 8px;
                    border-bottom: 1px solid #cacaca;
                    font-weight: 500;
                }
            }
        }

        .right-box {
            padding: 10px 0;
            width: 180px;

            p {
                margin-bottom: 0;
                line-height: 1.6;
            }
        }
    }
}

/* =========================================================================== */

.select2-container--open {
    .select2-dropdown {
        z-index: 7;
    }
}

.select2-container {
    width: 100% !important;
}

.select2-drpdwn {
    .select2-selection {
        border-radius: 0 !important;
        border-color: $light-color !important;
        height: 40px !important;
        padding: 5px;
    }

    .form-control {
        border-radius: 5px;
    }

    .form-control-primary {
        border-color: var(--theme-color);
        color: var(--theme-color);
    }

    .form-control-secondary {
        border-color: var(--theme-secondary);
        color: var(--theme-secondary);
    }

    .form-control-success {
        border-color: $success-color;
        color: $success-color;
    }

    .form-control-info {
        border-color: $info-color;
        color: $info-color;
    }

    .form-control-warning {
        border-color: $warning-color;
        color: $warning-color;
    }

    .form-control-danger {
        border-color: $danger-color;
        color: $danger-color;
    }

    .form-control-inverse {
        border-color: $dark-color;
        color: $dark-color;
    }

    .form-control-primary-fill {
        background-color: var(--theme-color);
        color: $white;
    }

    .form-control-secondary-fill {
        background-color: var(--theme-secondary);
        color: $white;
    }

    .form-control-success-fill {
        background-color: $success-color;
        color: $white;
    }

    .form-control-info-fill {
        background-color: $info-color;
        color: $white;
    }

    .form-control-warning-fill {
        background-color: $warning-color;
        color: $white;
    }

    .form-control-danger-fill {
        background-color: $danger-color;
        color: $white;
    }

    .form-control-inverse-fill {
        background-color: $dark-color;
        color: $white;
    }
}

//colors
$color_bon_jour_approx: #e0e0e0;
$color_cape_cod_approx: #424242;

.selection {
    .select2-selection {
        border-radius: 5px !important;

        .select2-search__field {
            margin-top: 0;
            padding: 3px;
        }
    }
}

.select2-container--classic {
    .select2-selection--multiple {
        .select2-selection__choice {
            padding: 2px 6px !important;
            margin-top: 0 !important;
            background-color: var(--theme-color) !important;
            border-color: darken($primary-color, 10%) !important;
            color: $white;
            margin-right: 8px !important;
        }

        .select2-selection__choice__remove {
            color: $white !important;
            float: right;
            margin-left: 0.5rem;
        }
    }

    .select2-results__option--highlighted[aria-selected] {
        background-color: $color_bon_jour_approx !important;
        color: $color_cape_cod_approx !important;
    }

    .select2-results__options .select2-results__option[aria-selected="true"] {
        background-color: var(--theme-color) !important;
        color: $white !important;
    }
}

.select2-container--default {
    .select2-selection--single {
        .select2-selection__arrow {
            top: 7px !important;
            right: 10px !important;
        }
    }

    .select2-selection--multiple {
        .select2-selection__choice {
            padding: 2px 6px !important;
            margin-top: 0 !important;
            background-color: var(--theme-color) !important;
            border-color: darken($primary-color, 10%) !important;
            color: $white;
            margin-right: 8px !important;
        }

        .select2-selection__choice__remove {
            color: $white !important;
            float: right;
            margin-left: 0.5rem;
        }
    }

    .select2-results__option--highlighted[aria-selected] {
        background-color: $color_bon_jour_approx !important;
        color: $color_cape_cod_approx !important;
    }

    .select2-results__options .select2-results__option[aria-selected="true"] {
        background-color: var(--theme-color) !important;
        color: $white !important;
    }
}

/**=====================
       Select 2 CSS Ends
  ==========================**/
