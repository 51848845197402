/**=====================
     JS datagrid CSS Start
==========================**/
.jsgrid {
    .jsgrid-grid-body {
        .jsgrid-cell {
            padding: 0.75rem;
        }
    }

    .jsgrid-pager-container {
        text-align: right;
    }

    .jsgrid-pager {
        padding: 0;
        margin-top: 20px;

        .jsgrid-pager-nav-inactive-button {
            margin-right: -5px;

            &:first-child {
                border-radius: 5px 0 0 5px;
            }
        }

        .jsgrid-pager-page {
            &~.jsgrid-pager-nav-button {
                margin-left: -5px;

                &:last-child {
                    border-radius: 0 5px 5px 0;
                }
            }
        }

        [class*="jsgrid-pager"] {
            display: inline-block;
            min-width: 1.5em;
            padding: 0.5em 1em;
            border: 1px solid $light-color;
        }

        .jsgrid-pager-page {
            a {
                color: $dark-color;
            }
        }

        .jsgrid-pager-current-page {
            color: $dark-color;
        }
    }

    .jsgrid-selected-row {
        >.jsgrid-cell {
            background: $light-color;
            border-color: $auth-bg-color;
        }
    }

    .jsgrid-header-row {
        >.jsgrid-header-cell {
            padding: 0.75rem;
            font-weight: 500;
        }
    }
}

/**=====================
       JS datagrid CSS Ends
  ==========================**/