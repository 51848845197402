/**=====================
     Gallery CSS Start
==========================**/
.profile-img-style,
.scroll-bar-wrap {
    img {
        width: 100%;
    }
}

.gallery {
    margin-bottom: -30px;

    >a {
        margin-bottom: 30px;

        &:before {
            @include pos($pos: absolute, $content: "\edee");
            font-family: $font-ICO;
            @include pseudowh($width: calc(100% - 40px), $height: calc(100% - 40px));
            left: 20px;
            background-color: rgba($white, $alpha: .27);
            top: 5px;
            transform: scale(0);
            transition: all 0.3s ease;
            @include flex_common;
            font-size: 30px;
            color: $black;
        }

        &:hover {
            &:before {
                transform: scale(1);
                transition: all 0.3s ease;
            }
        }

        .img-thumbnail {
            border-radius: 0;
        }
    }
}

.gallery-with-description {
    margin-bottom: -30px;

    a {
        >div {
            padding: 10px;
            border: 1px solid lighten($dark-color, 70%);
            margin-bottom: 30px;
            border-radius: 0 0 5px 5px;
        }

        &:hover {
            text-decoration: none !important;
        }
    }

    h4 {
        color: $dark-color;
        margin-top: 15px;
        font-size: 18px;
    }

    p {
        color: lighten($dark-gray, 10%);
    }
}

.lg-backdrop {
    background-color: $white;
}

.lg-outer {
    .lg-actions {

        .lg-next,
        .lg-prev {
            background-color: $white;
            border: 1px solid $light-semi-gray;

            &:hover {
                color: $dark-color;
            }
        }
    }

    .lg-toolbar {
        background-color: rgba($white, $alpha: .45);
        border-bottom: 1px solid $dark-color;

        .lg-icon:hover {
            color: $dark-color;
        }
    }

    #lg-counter {
        color: $dark-color;
    }

    .lg-sub-html {
        background-color: rgba($white, $alpha: .45);

        h4 {
            font-size: inherit;
            color: $dark-color;
        }

        p {
            color: $dark-color;
        }
    }

    .lg-thumb-outer {
        background-color: $light-gray;
    }

    .lg-thumb-item {
        padding: 4px;
        border: 1px solid $light-semi-gray;
        border-radius: 0;

        .active,
        &:hover {
            border-color: $black;
        }
    }
}

.gallery {
    a {

        &:before,
        &:after {
            content: none;
        }
    }

    .img-hover {
        >div {
            overflow: hidden;
        }
    }

    .hover-1 {
        img {
            transform: scale(1);
            transition: .3s ease-in-out;

            &:hover {
                transform: scale(1.2);
            }
        }
    }

    .hover-2 {
        img {
            @include pseudowh($width: 100%, $height: auto);
            transition: 0.3s ease-in-out;
            transform: translateX(0px);
        }

        &:hover {
            img {
                transform: translateX(40px) scale(1.2);
            }
        }
    }

    .hover-3 {
        img {
            transform: scale(1.2);
            transition: .3s ease-in-out;
        }

        &:hover {
            img {
                transform: scale(1);
            }
        }
    }

    .hover-4 {
        img {
            transform: scale(1.5);
            transition: .3s ease-in-out;
        }

        &:hover {
            img {
                transform: scale(1);
            }
        }
    }

    .hover-5 {
        img {
            margin-left: 30px;
            transform: scale(1.3);
            transition: .3s ease-in-out;
        }

        &:hover {
            img {
                margin-left: 0;
            }
        }
    }

    .hover-6 {
        img {
            transform: rotate(15deg) scale(1.4);
            transition: .3s ease-in-out;
        }

        &:hover {
            img {
                transform: rotate(0) scale(1);
            }
        }
    }

    .hover-7 {
        img {
            filter: blur(3px);
            transition: .3s ease-in-out;
        }

        &:hover {
            img {
                filter: blur(0);
            }
        }
    }

    .hover-8 {
        img {
            filter: grayscale(100%);
            transition: .3s ease-in-out;
        }

        &:hover {
            img {
                filter: grayscale(0);
            }
        }
    }

    .hover-9 {
        img {
            filter: sepia(100%);
            transition: .3s ease-in-out;
        }

        &:hover {
            img {
                filter: sepia(0);
            }
        }
    }

    .hover-10 {
        img {
            filter: grayscale(0) blur(0);
            transition: .3s ease-in-out;
        }

        &:hover {
            img {
                filter: grayscale(100%) blur(3px);
            }
        }
    }

    .hover-11 {
        img {
            opacity: 1;
            transition: .3s ease-in-out;
        }

        &:hover {
            img {
                opacity: .5;
            }
        }
    }

    @keyframes flash {
        0% {
            opacity: .4;
        }

        100% {
            opacity: 1;
        }
    }

    .hover-13 {
        &:hover {
            img {
                opacity: 1;
                animation: flash 1.5s;
            }
        }
    }

    @keyframes shine {
        100% {
            left: 125%;
        }
    }

    .hover-14 {
        div {
            position: relative;

            &:before {
                @include pos;
                top: 0;
                left: -75%;
                z-index: 2;
                display: block;
                @include pseudowh($width: 50%, $height: 100%);
                background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
                transform: skewX(-25deg);
            }

            &:hover {
                &:before {
                    animation: shine .75s;

                }
            }
        }
    }

    .hover-15 {
        div {
            position: relative;

            &:before {
                @include pos;
                @include center(both);
                z-index: 2;
                display: block;
                @include pseudowh($width: 0, $height: 0);
                background: rgba(255, 255, 255, .2);
                border-radius: 100%;
                opacity: 0;
            }

            &:hover {
                &:before {
                    animation: circle .75s;
                }
            }
        }
    }
}

@keyframes circle {
    0% {
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    100% {
        @include pseudowh($width: 200%, $height: 200%);
        opacity: 0;
    }
}

.grid {
    position: relative;
}

/* clear fix */
.grid:after {
    content: '';
    display: block;
    clear: both;
}

/* ---- .grid-item ---- */

.grid-item {
    img {
        max-width: 100%;
        padding: 0.25rem;
        background-color: $white;
        border: 1px solid #dee2e6;
    }
}

.gallery-with-description {
    .grid-item {
        img {
            border: 0;
            padding: 0;
        }
    }
}

/**=====================
     Gallery CSS Ends
==========================**/