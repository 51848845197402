/**=====================
    Tabs CSS Start
==========================**/
.nav-pills {

    .nav-link.active,
    .show>.nav-link {
        background-color: var(--theme-color);
    }
}

.nav {
    i {
        margin-right: 10px;
    }
}

.nav-right {
    justify-content: flex-end;
}

$nav-name:theme,
primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$nav-color:var(--theme-color),
var(--theme-color),
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;

@each $var in $nav-name {
    $i: index($label-name, $var);

    .nav-#{$var},
    .nav-pills.nav-#{$var} {
        .nav-link {
            color: nth($nav-color, $i);
        }

        .nav-link.active,
        .show>.nav-link {
            background-color: nth($nav-color, $i);
            color: $white;
        }
    }
}

.nav-tabs.nav-bottom {
    .nav-item {

        .nav-link.active,
        .nav-link.focus,
        .nav-link.hover {
            border-color: $white $pattens-blue $pattens-blue;
        }
    }
}

.tab-content {
    ~.nav-tabs {
        &.border-tab {
            margin-bottom: 0;
            margin-top: 30px;
        }
    }
}

// Material style css
.border-tab.nav-tabs {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 30px;

    .nav-item {
        .nav-link {
            font-size: 16px;
            color: $dark-color;
            text-align: center;
            padding: 10px 25px;
            border: none;
            border-bottom: none;
            transition: 0.3s;

            &.active,
            &.show,
            &:focus,
            &:hover {
                color: var(--theme-color);
            }

            &.active,
            &.show,
            &:focus {
                border-bottom: none;
            }
        }
    }
}

.border-tab.nav-left {
    .nav-link {
        border-left: 2px solid transparent;
        transition: all 0.3s ease;
        color: $dark-color;
    }

    .nav-link.active,
    .show>.nav-link {
        background-color: transparent;
        border-left-color: var(--theme-color);
        color: var(--theme-color);
        border-radius: 0;
        transition: all 0.3s ease;
    }
}

.border-tab.nav-right {
    .nav-link {
        border-right: 2px solid transparent;
        transition: all 0.3s ease;
        color: $dark-color;
        text-align: right;
    }

    .nav-link.active,
    .show>.nav-link {
        background-color: transparent;
        border-right-color: var(--theme-color);
        color: var(--theme-color);
        border-radius: 0;
        transition: all 0.3s ease;
    }
}

$m-nav-name:theme,
primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$nav-color:var(--theme-color),
var(--theme-color),
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;

@each $var in $m-nav-name {
    $i: index($label-name, $var);

    .border-tab.nav-#{$var} {

        .nav-link.active,
        .nav-item.show,
        .nav-link:focus,
        .nav-link:hover {
            color: nth($nav-color, $i);
            background-color: transparent;
        }

        .nav-item {
            .nav-link {
                transition: 0.3s;

                &.active,
                &.show,
                &:focus {
                    border-bottom: 2px solid nth($nav-color, $i);
                }

                &.active,
                &.show,
                &:focus,
                &:hover {
                    color: nth($nav-color, $i);
                }
            }
        }
    }

    .border-tab.nav-left.nav-#{$var} {

        .nav-link.active,
        .show>.nav-link {
            border-left-color: nth($nav-color, $i);
            color: nth($nav-color, $i);
        }
    }

    .border-tab.nav-right.nav-#{$var} {

        .nav-link.active,
        .show>.nav-link {
            border-right-color: nth($nav-color, $i);
            color: nth($nav-color, $i);
        }
    }
}

.tabbed-card {
    ul {
        position: absolute;
        right: 22px;
        top: 33px;
        border-bottom: none;

        &.border-tab {
            &.nav-tabs {
                top: 12px;

                .nav-item {
                    float: right;

                    .nav-link {
                        padding: 32px 15px;
                    }
                }
            }
        }
    }
}

.nav-pills {
    border-bottom: 1px solid $gray-light;

    .nav-item {
        margin-right: 20px;

        .nav-link {
            border: none;
            padding: 10px 25px;
            background-color: transparent;
            position: relative;
            color: $black1;
            font-size: 16px;
            font-weight: 600;

            &:after {
                @include pos;
                @include pseudowh($width: 100%, $height: 2px);
                bottom: 0;
                left: 0;
                background-color: var(--theme-color);
                opacity: 0;
            }

            &:hover {
                &::after {
                    opacity: .6;
                }
            }

            &.active {
                &::after {
                    opacity: 1;
                }
            }
        }
    }
}

/**=====================
       Tabs CSS Ends
  ==========================**/